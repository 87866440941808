import React from "react";
import { formatCurrency, formatShortDate } from "common/Format2";
import Barcode from "react-barcode";

const BillJATOrder = React.forwardRef((props, ref) => {
  const { data, cus, config, shopAddres, customerAddres } = props;
  return (
    <div className="bodyPrint" ref={ref}>
      <style>{`@media print {body{zoom: 94%;}}`}</style>
      <div style={{ marginTop: 20, marginBottom: 10 }}>
        <div style={{ marginLeft: 40, marginRight: 20 }}>
          <div style={{ fontWeight: "bold", fontSize: 15 }}>{config.shop_name}</div>
          <div style={{ fontWeight: "bold" }}>{`Địa chỉ:${config.shop_address}, ${shopAddres.ward}, ${shopAddres.district}, Tỉnh ${shopAddres.province}`}</div>
          <div style={{ fontWeight: "bold" }}>{`SDT:${config.shop_phone}`}</div>
        </div>
        {/* <Barcode value="1654164651" width={1} height={30} fontSize={10} /> */}
        <div style={{ textAlign: "center" }}>
          <div>
            <Barcode value={data.transpost_id} width={1} height={30} fontSize={10} />
          </div>
          <div>{`Thu hộ (COD): ${formatCurrency(data.is_freeship || cus.is_wholesale ? data.total_price : data.total_price + config.ship_fee)}đ`}</div>
          <div>{`(Tiền hàng: ${formatCurrency(data.total_price)} + Tiền ship: ${formatCurrency(
            data.is_freeship || cus.is_wholesale ? 0 : config.ship_fee
          )}`}</div>
          <div>{`Số: ${data.id}`}</div>
          <div>{`Khối lượng ship (g): ${data.weight ? data.weight * 1000 : config.weight * 1000}`}</div>
        </div>
        <div>
          <div>
            <span style={{ fontWeight: "bold" }}>Người nhận:</span>
            {` ${cus.name}`}
          </div>
          {cus.is_wholesale ? <div>Khách sỉ</div> : <></>}
          <div>
            <span style={{ fontWeight: "bold" }}>Địa chỉ:</span>
            {`${cus.address}, ${customerAddres.ward}, ${customerAddres.district}, Tỉnh ${customerAddres.province}`}
          </div>
          <div>
            <span style={{ fontWeight: "bold" }}>Điện thoại:</span>
            {` ${cus.phone}`}
          </div>
          <div style={{ minHeight: 50 }}>
            <span style={{ fontWeight: "bold" }}>Ghi chú giao hàng:</span>
          </div>
        </div>
        <div style={{ textAlign: "center" }}>
          <div>
            <Barcode value={data?.jat_respone?.ResponseItems[0].DispatchSite} width={1} height={30} fontSize={10} />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ fontSize: 15, fontWeight: "bold", padding: 1, border: "1px solid black" }}>
              {`${data?.jat_respone?.ResponseItems[0].Code} - ${data?.jat_respone?.ResponseItems[0].DispatchSite} - ${data?.jat_respone?.ResponseItems[0].SortLine} - ${data?.jat_respone?.ResponseItems[0].Transport}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
export default BillJATOrder;
